#refinery-intake .dropdown-multiselect {
  position: relative;
  height: 20px;
  left: 30px;
  top: 2px;
}

.refinery-intake .control-dd {
  width: 200px !important;
}

.separator {
  border-bottom: 1px solid #7f7f7f;
  position: relative;
  cursor: pointer;
  background-color: #202020;
  height: 20px;
}

.icon-title {
  position: absolute;
}
.title {
  background-color: #202020;
}
.header {
  background: transparent;
  min-width: 117px;
  padding: 6px;
  border: 1px solid #404040;
  /* margin: 0px 1px 2px 0px !important; */
  border-radius: 4px;
  font-weight: normal;
}
table {
  width: 100%;
  border-collapse: collapse;
}
#wrap .td-data {
  border: 1px solid #404040;
  border-radius: 4px;
  text-align: center;
  margin: 0 !important;
  background-color: #303030;
}

#wrapmodel .td-data {
  padding: 6px;
  border: 1px solid #404040;
  border-radius: 4px;
  text-align: center;
  margin: 0 !important;
  background-color: #303030;
}
#wrapmodel input {
  text-align: center;
}

.display {
  display: block !important;
}
.hide {
  display: none !important;
}
.editIcon {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 2px;
  background-color: #303030;
  right: -16px;
  z-index: 999;
}
.iconfa {
  font-size: 12px !important;
  padding: 9px !important;
  color: #d9d9d9 !important;
}
.iconfa:hover {
  background: rgb(0, 60, 136);
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -1px;
  left: -1px;
}

/* .tbodycontent:nth-child(1) div {
  background: rgb(0, 60, 136);
} */
.bgc {
  background: rgb(0, 60, 136) !important;
}

.page-loading-spinner {
  position: absolute;
  transform: translate(0, 37%);
  height: 100%;
  width: 100%;
}

/* .tbodycontent:nth-child(1) td:first-child div {
  background-color: #202020 !important;
} */

.tbodycontent:nth-child(1) th:first-child div {
  border: none !important;
}
.pos {
  position: relative;
}

.edit-input,
.edit-textarea {
  background: transparent;
  border: 0;
  outline: none;
}

.edit-textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #444444;
  border-radius: 5px;
  padding: 10px;
}

.ant-modal-body .tbodycontent:nth-child(1) div {
  background: transparent;
}

.edittable {
  overflow-x: scroll;
  height: 100px;
  overflow-y: hidden;
  margin-bottom: 40px;
}

.non-edit-input {
  background: none;
  border: 0;
  cursor: not-allowed;
}

.k-loading-mask {
  z-index: 1030;
}

#container {
  width: 100%;
}
table#name {
  width: 5%;
  float: left;
}

#wrap {
  width: 90%;
  overflow: auto;
  overflow-y: hidden;
  float: left;
}

.baseline-lock {
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
}
