.events-window .k-window-content {
  padding: 0;
}

.events-window .k-window .k-window-titlebar {
  padding: 0rem 1.5rem 1px;
  position: relative;
}

.events-window .k-window-wrapper > .k-window-titlebar button:nth-child(-n + 3) {
  display: none;
}

.events-window .k-bare {
  position: relative;
  top: 0;
  right: -9px;
  color: #7f7f7f;
}

.events-window .k-dropdown-wrap {
  border: 0;
  background: none;
}

.events-window .k-i-close {
  font-size: 20px;
}

#multiSelect .k-i-close {
  font-size: 12px;
}

.events-window .input {
  width: 50px;
}

.events-window .input input {
  outline: 0;
}

#event-listing table {
  width: 100%;
}
#event-listing th {
  background-color: #282828 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: 'ShellLight';
  border-top: 1px solid #404040;
  width: auto;
}

#event-listing tr {
  background-color: #303030;
}

#event-listing tr:nth-child(even) {
  background-color: #383838;
}
#event-listing tr:hover {
  background-color: rgb(0, 60, 136, 0.2);
}

#event-listing tr.selected-row {
  background-color: #003c88;
}

.ellipse-table {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

li.capacity-event-tab-btn.active,
li.maintenance-event-tab-btn.active {
  border-left: 1px solid rgba(255, 255, 255, 0.125);
  border-top: 1px solid rgba(255, 255, 255, 0.125);
  border-right: 1px solid rgba(255, 255, 255, 0.125);
  color: #f7f7f7;
  z-index: 999;
  background: #282828;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
}

.maintenance-currentyear-dropdown button {
  font-size: 12px;
}

.incremental-capacity input:focus {
  border: none;
  outline: 0px;
}

#multiSelect .k-button {
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
  background-color: #303030;
}

button.k-button.k-state-active.history {
  background-color: #003c88 !important;
}

button.action,
li.action {
  background-color: #1d1d1d;
}

button.action:hover,
li.action:hover {
  background-color: #303030;
}

#multiSelect .k-button {
  background-color: #1d1d1d;
}

#multiSelect .k-button:hover {
  background-color: #303030;
}

/* .url-input {
  width: 95%;
  margin-left: 5%;
} */

.maintenance-event-grid div.k-state-focused button {
  background-color: #1d1d1d;
}

.maintenance-event-grid div.k-state-focused button.k-primary {
  background-color: #003c88;
}

.datepicker .k-datepicker {
  width: 100%;
}

.k-dateinput-wrap {
  background-color: #202020 !important;
}

.k-select {
  background-color: #202020 !important;
}

/* .datetime .datepicker span.k-select {
  padding: 0;
  position: absolute;
  top: 8px;
  width: 100%;
  align-items: end;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  right: 0;
} */

.events-title {
  text-align: center;
}

.events-title div {
  background: #383838;
  padding: 5px;
  width: 200px;
  margin: 0 auto 2px;
  border-radius: 0px 0px 10px 10px;
  border-bottom: 1px solid #404040;
  border-left: 1px solid #404040;
  border-right: 1px solid #404040;
  margin-top: 3px;
}

.incremental-capacity.ae input {
  width: 55%;
}

/* .ceventth {
  width: 140px !important;
} */

/* .events-process li.active {
  background: #282828;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
} */

.capacity-event-grid th.py-2 {
  position: sticky;
  top: 0;
  z-index: 99;
  border: 0px !important;
}

.events-process {
  position: absolute;
  bottom: -20px;
}

.events-window .title,
.events-window .comment-box-title {
  font-size: 11px;
}

tr.ant-table-row:nth-child(odd) {
  cursor: pointer;
  background-color: #383838;
}

tr.ant-table-row:nth-child(even) {
  cursor: pointer;
  background-color: #303030;
}

tr.ant-table-row:hover > td {
  background: #002453 !important;
}

tr.ant-table-row-selected > td {
  background: #003c88 !important;
}

.ant-modal-wrap {
  background: #00000063;
}

.ant-radio-inner {
  border-color: darkgray !important;
}
.ant-radio-inner::after {
  background-color: darkgray !important;
}

.events-window .k-input {
  height: 38px;
}

.events-window .datepicker span.k-select {
  right: 0;
  top: 12px;
}

.events-window .datepicker.capacity-add-edit span.k-select {
  right: 12px;
  top: 12px;
}

.events-window .capacity-datepicker .datepicker span.k-select {
  right: 8px;
  top: 12px;
}

/* .ceventtd.grid-longtext {
  width: 145px !important;
}

.ceventth.grid-longtext {
  width: 170px !important;
} */

.events-window .filter .fa {
  font-size: 14px;
}

.events-window .k-multiselect-wrap .k-button {
  min-height: calc(1.5 * 1em + 0.25rem + 2px + 2px);
  padding: calc(0.125rem + 4px) 0.375rem;
}

.event-filter button {
  font-family: 'ShellLight';
  font-size: 12px;
}

thead.fixed-header {
  position: sticky;
  top: -1px;
}

.new-event-select-all .capacity-add-edit .k-widget.k-datepicker {
  width: 100%;
}
