.reclassification-common .reclassification-content {
  position: absolute;
  left: 50%;
  z-index: 9999;
  width: 900px;
  height: 450px;
  background: #202020;
  color: #d9d9d9;
  transform: translate(-50%, -150%);
  box-sizing: border-box;
  border-style: solid;
  border-color: #676363;
  border-width: 1px;
}

.reclass-head {
  padding: 10px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: #686868;
}

.reclass-quantity-data {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #7f7f7f !important;
}

.reclass-grid-headers {
  overflow: hidden;
  padding: 10px;
  border-width: 1px;
  border-top: solid;
  border-bottom: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: #686868;
}

.reclass-grid {
  padding-left: 20px;
  padding-right: 20px;
  border-width: 1px;
}

.reclass-grid-header1 {
  display: inline;
  padding-left: 30px;
}

.reclass-grid-header2 {
  display: inline;
  padding-left: 179px;
  padding-right: 73px;
}

.reclass-grid-header3 {
  display: inline;
  padding-left: 10px;
}

ul.reclassification-list {
  overflow: hidden;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: #686868;
}
li.reclass-item1 {
  display: inline-block;
  padding-left: 10px;
  width: 250px;
  text-align: left;
}

li.reclass-item2 {
  display: inline-block;
  padding-left: 10px;
  padding-right: 100px;
}

li.reclass-item3 {
  display: inline-block;
  border: solid;
  border-width: 1px;
  /* padding-bottom: 5px; */
  border-color: #757070;
  border-radius: 0.25rem;
}

button.reclass-cancel {
  background-color: #1d1d1d;
  color: #ffffff;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: 0;
  position: relative;
}

button.reclass-apply {
  color: #fff;
  background-color: #003c88;
  border-color: #003c88;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: 0;
  position: relative;
  margin: 5px;
}

.reclass-grid-contents {
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden auto;
  height: 200px;
}

.reclass-actions {
  float: right;
  display: block;
  padding: 10px;
}

ul.reclassification-list {
  background: rgb(56, 54, 54);
}
ul.reclassification-list:nth-child(odd) {
  background: #292828;
}

/* input.reclass-capacity {
  color: #fff;
  background: rgb(32, 32, 32);
  border-right: none;
  border-bottom: none;
  border: solid;
  border-width: 1px;
  border-color: #757070;
  padding-bottom: 5px;
} */

.container-cl {
  margin: 0 auto;
}

/* .cl {
  line-height: 24pt;
  border: solid 1px black;
}

div.container-cl > div:nth(odd) {
  background: #e0e0e0;
} */

div.container-cl:nth-child(even) {
  background: #383838;
  border-top: 1px solid #404040;
}
div.container-cl:nth-child(odd) {
  background: #303030;
  border-top: 1px solid #404040;
}

.grid-head {
  background-color: #282828 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: 'ShellLight';
  border-top: 1px solid #404040;
}

.tr-height {
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.text-danger-general-red {
  color: red;
}
