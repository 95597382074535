.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  opacity: 1;
  z-index: 1009;
}

.popup {
  margin: 10% auto;
  width: 50%;
  position: relative;
  background: #202020;
  color: #d9d9d9;
  box-sizing: border-box;
  border: 1px solid #676363;
}

.popup-head {
  padding: 10px 10px 0;
  font-family: 'ShellHeavy';
  font-size: 20px;
}

.popup-text {
  padding: 10px 10px 15px;
}

.popup .content {
  padding: 0;
}
.popup table {
  border-collapse: collapse;
  width: 100%;
}
.popup .table-content {
  overflow-y: scroll;
  max-height: 300px;
}
.popup table thead th {
  background-color: #303030 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: 'ShellLight';
  border-top: 1px solid #404040;
  padding: 10px;
  position: sticky;
  top: 0;
}
.popup td {
  padding: 10px;
}
.popup tbody tr:nth-child(even) {
  background: #303030;
}
.popup tbody tr:nth-child(odd) {
  background: #383838;
}
.action-container {
  float: right;
  display: block;
  margin: 10px;
  text-align: right;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}
.k-overlay {
  z-index: 1;
  position: fixed !important;
}
