.global-menu .dropdown-menu {
  box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.4);
  left: -0.3rem !important;
  width: 21rem;
  transform: translate3d(56px, 0px, 0px) !important;
  height: 100%;
}

.global-menu.dropright {
  position: unset;
}

.Collapsible__trigger .is-open {
  border: 0;
  box-sizing: unset;
}

.global-menu .dropdown-item {
  padding: 0.7rem 0 0.7rem 0.475rem;
  font-size: 14px;
  line-height: 17px;
}

.sub-heading {
  font-family: 'Futura Std';
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}

.global-menu .sidebar-menu-item .dropdown-menu b {
  padding: 0.7rem 0 0.7rem 0.475rem;
  font-size: 14px;
  line-height: 17px;
}
