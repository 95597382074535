@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellBook.woff2) format('woff2'),
    url(./fonts/ShellBook.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellBookItalic.woff2) format('woff2'),
    url(./fonts/ShellBookItalic.woff) format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellMedium.woff2) format('woff2'),
    url(./fonts/ShellMedium.woff) format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellMediumItalic.woff2) format('woff2'),
    url(./fonts/ShellMediumItalic.woff) format('woff');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellLight.woff2) format('woff2'),
    url(./fonts/ShellLight.woff) format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellLightItalic.woff2) format('woff2'),
    url(./fonts/ShellLightItalic.woff) format('woff');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellHeavy.woff2) format('woff2'),
    url(./fonts/ShellHeavy.woff) format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Shell Font';
  src: local('Shell Font'), local('ShellFont'), url(./fonts/ShellBold.woff2) format('woff2'),
    url(./fonts/ShellBold.woff) format('woff');
  font-style: normal;
  font-weight: bold;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
