.Collapsible .is-closed .dropdown-header:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f067';
  float: right;
  font-size: 14px;
  padding: 5px 0;
}

.Collapsible .is-open .dropdown-header:after {
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f068';
  float: right;
  font-size: 14px;
  padding: 5px 0;
}

.program-header-title {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1px;
  border-bottom: 0.1em solid gray;
}

.program-header-title b {
  color: grey;
}
.program-header-spacing {
  border-bottom: 0.1em solid gray;
  border-top: 0.1em solid gray;
}

.program-header-spacing b {
  font-family: ShellMedium;
  font-size: 16px;
  color: #d9d9d9;
}

.searchBox {
  position: relative;
  margin-bottom: 10px;
  padding: 0.4rem;
  border: 1px solid gray;
  border-radius: 5px;
}

.inpuBox {
  position: absolute;
  border: 0;
  background: transparent;
}

.spanbox {
  position: absolute;
}

.k-i-collapse,
.k-i-expand {
  position: absolute;
  right: 0px;
  margin-right: 12px;
}

.k-i-collapse::before {
  font-family: 'FontAwesome';
  color: #7f7f7f;
  content: '\f146';
  background: transparent;
  margin: 1px;
}

.k-i-expand::before {
  font-family: 'FontAwesome';
  color: #7f7f7f;
  content: '\f0fe';
  background: transparent;
  margin: 1px;
}

.k-treeview-lines > .k-treeview-item {
  padding: 0px;
}

/* 
.k-treeview-item > .k-mid span.k-icon {
  display: none;
} */

.k-treeview-item > .k-animation-container .k-mid span.k-icon {
  display: block;
}

.k-treeview .k-in.k-state-focused {
  box-shadow: none;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item {
  padding: 0px;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}

input:focus::-moz-placeholder {
  color: transparent !important;
}

input:focus:-ms-placeholder {
  color: transparent !important;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid {
  border-bottom: 1px solid #595959;
  margin-left: 10px;
}

.dropdown-menu {
  background-color: #202020;
}

.k-treeview .k-in {
  margin-left: 10px;
}

.k-treeview-lines > .k-treeview-item > .k-mid {
  background-color: #282828;
}

.k-treeview .k-in.k-state-hover,
.k-treeview .k-in:hover {
  border-color: transparent;
  background-color: transparent;
}

.k-animation-container > .k-child-animation-container {
  margin-bottom: 10px;
}

.k-treeview-lines > .k-treeview-item > .k-mid > .k-in div > a {
  color: gray !important;
  cursor: default;
  pointer-events: none;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid
  > .k-in {
  margin-left: -4px;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid
  > .k-in
  div
  > a {
  width: 100%;
  display: inline-block;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid
  > .k-in
  div {
  width: 312px;
  text-align: left;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid
  > .k-in
  div {
  width: 280px;
  text-align: left;
}

.k-treeview-lines
  > .k-treeview-item
  > .k-animation-container
  > .k-child-animation-container
  > .k-content
  > .k-treeview-item
  > .k-mid
  > .k-in
  div
  > a {
  width: 100%;
  display: inline-block;
}
