/* #assetconfigwindow .k-window-titlebar {
  padding: 15px 10px 15px 10px;
  border-style: none;
} */
#assetconfigwindow .k-window-title {
  font-family: 'ShellHeavy';
  font-size: 15.75px;
  padding-left: 10px;
  font-weight: normal;
}
#assetconfigwindow .k-link {
  font-weight: normal;
}

#assetconfigwindow .k-window-content {
  padding-top: 0;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border-bottom: 1px solid #595959;
}

/* Style the buttons inside the tab */
.tab li {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #a6a6a6;
  border-bottom: 3px solid #202020;
}

/* Change background color of buttons on hover */
.tab li:hover {
  background-color: #282828;
  color: #f7f7f7;
}

/* Create an active/current tablink class */
.tab li.active {
  border-bottom: 3px solid #003c88;
  color: #f7f7f7;
  z-index: 999;
}

/* Style the tab content */
.tabcontent {
  display: none;
  /* padding: 6px 12px; */
  border-top: none;
  height: 650px;
  overflow: auto;
}
.defalut-height {
  overflow-x: hidden;
  overflow-y: auto;
}
.pannel {
  border: 1px solid #595959;
  background-color: #282828;
  padding: 20px 25px 25px 25px;
}

#assetconfigwindow .background-filter-fill {
  background-color: #282828;
}
.red-text {
  color: red;
  font-size: 9px !important;
}
#assetconfigwindow .k-dropdown,
#assetconfigwindow .k-textbox,
#assetconfigwindow .k-datepicker,
#assetconfigwindow .k-numerictextbox {
  width: 80%;
  border-color: #595959;
  color: white;
  background-color: #1d1d1d;
  height: 40px;
}
#assetconfigwindow .k-input,
#assetconfigwindow .k-select,
#assetconfigwindow .k-dropzone {
  background-color: #1d1d1d;
  height: 38px;
}

#assetconfigwindow .k-textbox.k-state-invalid {
  color: #dc3545;
  border-color: #dc3545;
}

.titlebar {
  border-bottom: 1px solid #595959;
  margin-bottom: 18px !important;
  font-size: 12px;
}
.devider {
  font-size: 20px;
  margin-right: 20px;
  color: #a6a6a6;
  border-right: 1px solid #595959;
}
.k-checkbox-label,
.k-radio-label {
  color: #a6a6a6;
}
.textareaInput {
  width: 93.4%;
  height: 100px;
  background-color: rgb(32, 32, 32);
  border-color: rgba(255, 255, 255, 0.125);
  color: rgb(217, 217, 217);
}

.textareaInput-ist {
  width: 100%;
  height: 100px;
  background-color: rgb(32, 32, 32);
  border-color: rgba(255, 255, 255, 0.125);
  color: rgb(217, 217, 217);
  outline: none;
}

.textareaInput:focus,
.textareaInput-ist:focus {
  outline: none;
}
.pt-30 {
  padding-top: 30px;
}
.linkicon {
  position: absolute;
  z-index: 999;
  right: 78px;
  font-size: 16px;
  padding: 8px;
}
/* #assetconfigwindow .k-widget {
  /* width: 79.8%; 
} */
#assetconfigwindow .k-file-extension-wrapper,
#assetconfigwindow .k-file-name-size-wrapper {
  display: none;
}
#assetconfigwindow .k-file-size {
  position: absolute;
  color: #a6a6a6;
  top: -57px;
  right: 0px;
  font-size: 12px;
}
#assetconfigwindow .k-upload-status {
  position: absolute;
  top: -31px;
  height: 0;
  padding: 0;
}
#assetconfigwindow .k-upload-status-total {
  top: 19px;
  left: 107px;
  color: green;
}
#assetconfigwindow .k-file-error,
#assetconfigwindow .k-reset,
#assetconfigwindow .k-file-progress {
  padding: 0 !important;
}
#assetconfigwindow .k-reset {
  background-color: #282828;
  border-color: #282828;
}
.h-container {
  background-color: #303030;
}
.h-container th {
  color: #a6a6a6;
  font-weight: normal;
}
.h-container td {
  color: #f7f7f7;
  padding-top: 5px;
}
.text-gray {
  color: #a6a6a6;
}
.bd-tb {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
#unit-cross-reference .k-widget {
  width: 100%;
}
