.calender {
  width: 250px;
  position: absolute;
  z-index: 9999;
}

.calender.left {
  top: 43px;
  left: -15px;
}

.calender.right {
  top: 43px;
  right: -15px;
}

.end-date-select {
  position: relative;
}

.ss-main .ss-single-selected,
.ss-main .ss-content {
  background: #202020;
  color: #f7f7f7;
}

.ss-main .ss-content .ss-list .ss-option.ss-disabled {
  background-color: #303030;
}

.ss-option-selected {
  background-color: #003688 !important;
}
.ss-option:hover {
  background-color: #002152 !important;
}

.option-values {
  padding-left: 21px !important;
}

.calender.left:after,
.calender.left:before {
  bottom: 100%;
  left: 15%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.calender.left:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #202020;
  border-width: 10px;
  margin-left: -28px;
}

.calender.left:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #595959;
  border-width: 12px;
  margin-right: 2px;
}

.calender:after,
.calender:before {
  bottom: 100%;
  right: 3%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.calender:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #202020;
  border-width: 10px;
  margin-right: 2px;
}

.calender:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #595959;
  border-width: 12px;
  margin-left: -30px;
}

.k-upload .k-action-buttons .k-upload-selected {
  color: #d9d9d9;
}
.ol {
  top: -6px;
  position: absolute;
  width: 100%;
  left: -14px;
  height: 33px;
  background: transparent;
  cursor: pointer;
}
.olicon {
  padding-top: 5px;
  position: absolute;
  right: -18px;
  top: 4px;
}
