.pbi-report-container { 
	height: 80vh; 
	width: 100%;
}

.pbi-report-container-large { 
	height: 85vh;  
	width: 100%;
}

.pbi-report-container iframe {
	border: none;
} 

.pbi-report-container-large iframe {
	border: none;
}

.token-list-form {
	list-style-type: none;
}

.token-list-form li {
	text-align: start;
	margin: 10px 0;
	padding-left: 20px;
	min-height : 36px ;
} 