a {
  cursor: pointer;
}

li.page-item {
  background: #303030;
}

li.page-item-icons {
  background: #303030;
  border-bottom: 0.5px solid #484545;
  border-top: 0.5px solid #484545;
  border-right: 0.5px solid #484545;
}
select.pagelimit-dropdown {
  background: #303030;
  color: white;
  height: 33px;
  margin-left: 10px;
  border: 1px solid #484545;
}

select.pagelimit-dropdown:focus {
  outline: none;
}

li.active-page .page-link {
  background: #003c88;
}

li.page-item-icons:hover {
  background: #242323;
}

button.paginaton-button {
  padding-top: 5px;
}

li.pagelimit-item {
  padding-left: 20px;
}

.page-item-icons:first-child {
  border-left: 0.5px solid #484545;
}

.pagelimit-item {
  float: right;
}
