#gridTemplateListings tr:nth-child(odd) {
  background-color: #303030;
}

#gridTemplateListings tr:nth-child(even) {
  background-color: #383838;
}

#gridTemplateListings tr.k-master-row:hover {
  background-color: rgb(0, 60, 136, 0.2);
}

#gridTemplateListings td {
  border-width: 1px 0 1px 0;
  text-align: left;
}

#gridTemplateListings .k-grid-toolbar {
  background-color: #282828;
}

#gridTemplateListings tr > .k-header {
  background-color: #282828;
  color: #a6a6a6;
  font-family: 'ShellLight';
  border-right: 2px solid #303030;
}

#gridTemplateListings .k-filter-row {
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: 'ShellLight';
  text-align: left;
  border-right: 1px solid #383838;
  border-left: 1px solid #383838;
}

#gridTemplateListings .k-grid-container {
  background-color: #202020;
}

#gridTemplateListings .k-link {
  font-size: 15px;
  font-weight: bold;
}

#gridTemplateListings .k-grid-header-wrap {
  background-color: #202020 !important;
}

#gridTemplateListings .k-grid-norecords td {
  position: fixed;
  margin: 5% auto;
  left: 0;
  right: 0;
  text-align: center;
  border: none;
  top: 50%;
}

#feedback .k-i-x {
  font-size: 24px;
}
