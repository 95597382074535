.chart-navcontainer {
  position: absolute;
  border-radius: 4px;
  overflow-x: hidden;
  padding: 15px;
  overflow-y: auto;
  z-index: 999;
}
.aside-panel {
  width: 200px;
  background: #282828;
  border: 1px solid #595959;
  padding: 5px;
  color: #a6a6a6;
}
.aside-panel li {
  padding-top: 3px;
  padding-bottom: 3px;
}
.aside-panel li:hover {
  background-color: #003c88;
  cursor: pointer;
  color: #f7f7f7;
}

.togleBtn:hover {
  background: rgb(32, 32, 32) !important;
  color: #f7f7f7;
}
.chart-navcontainer {
  padding: 0;
  padding: 6px;
}
.item-active {
  background-color: #003c88;
  color: #f7f7f7;
}
#dashboard-chart .chart-continer {
  height: 65%;
  width: auto;
  position: fixed;
  z-index: 999;
  margin: auto;
  right: 27px;
  left: 4%;
  top: 225px;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
