.maintenance-select label {
  font-size: 12px;
  position: absolute;
  top: -10px;
  padding: 0 8px;
  z-index: 99;
  left: 0.5rem;
}

.maintenance i.fa.fa-calendar,
.maintenance .k-input,
.maintenance .k-button {
  font-size: 14px;
}

.item-line-height label {
  line-height: 1.6em;
}

.button-filter {
  position: relative;
}

.dropdown-dialog {
  position: absolute;
  z-index: 100;
  min-width: 20rem;
  top: 2.7rem;
  min-height: 250px;
  font-size: 14px;
}

.current-year .k-dropdown-wrap {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-filter .k-datepicker {
  width: 16rem;
}

.button-filter .k-select {
  border-left: 0;
  padding: 0.5rem 1rem;
  padding-left: 0;
}

.checkbox-filter .k-checkbox-label {
  margin-top: 0.45rem;
}

.delta.dropdown-dialog {
  right: 0;
  font-size: 14px;
}

.delta-buttons .k-item {
  border: 0;
  padding: 0;
  border-radius: 0;
  background: none;
  font-size: 14px;
}

.delta-buttons .k-link {
  padding: 0.5rem 0.72rem;
}

.version-selectors input {
  text-align: center;
}

.version-selectors label {
  margin-bottom: 0;
}

.version-selectors select {
  padding: 4px;
  background: none;
  width: 100%;
}

label.select:after {
  content: '\f0d7';
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  padding: 0.5rem 1rem;
  pointer-events: none;
  box-sizing: border-box;
}

.delta-submit-button,
.delta-buttons .k-animation-container {
  width: 100%;
}

.sub-dialog-view {
  font-size: 14px;
}

.dropdown-dialog:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent #595959 transparent;
  top: -9px;
  position: absolute;
  z-index: 99;
}

.delta.dropdown-dialog:before {
  right: 10px;
}

.absolute.dropdown-dialog:before {
  left: 10px;
}

.maintenance .table {
  border-collapse: separate;
  margin-bottom: 0.15rem;
}

.maintenance .table.days-in-month td {
  padding: 0.7rem 0 0.25rem;
}

.maintenance .table td.data {
  background: #303030;
}

.maintenance .weekly-view tr.data-tr,
.maintenance .mqy-view tr.data-tr {
  background: #303030;
}

.maintenance .weekly-view td,
.maintenance .mqy-view td {
  padding: 5px 0;
}

.table .weekly-view-year,
td.data-title {
  vertical-align: middle;
}

.maintenance .weekly-view td.day-month {
  color: #7f7f7f;
}

.data-tr .border {
  border: 1px solid #595959 !important;
}

.text-h span {
  background: none;
  text-transform: uppercase;
  font-size: 12px;
  transform-origin: 0 0;
  margin: 3px;
}

.weekly-view .text-h.less-providers {
  margin-top: 30px;
}

.mqy-view .text-h.less-providers {
  margin-top: 14px;
}

.ftd-w {
  width: 80px;
}

.fth-w {
  width: 106px;
}

.no-bgc {
  background: none !important;
}

.m-text {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #d9d9d9;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .mqy-view{
  max-width:1200px;
} */

.maintenance td.month {
  width: 24px;
  height: 84px;
}

.maintenance td.month.less-providers {
  width: 24px;
  height: auto;
}

.maintenance table {
  position: relative;
}
.maintenance tr {
  position: relative;
}

.text-h {
  height: 24px;
  text-align: center;
  position: absolute;
  left: 0;
  transform-origin: top left;
}
.weekly-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-165%);
  transform: rotate(-90deg) translate(-165%);
}

.daily-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-126%);
  transform: rotate(-90deg) translate(-126%);
}
.mqy-view .text-h {
  -webkit-transform: rotate(-90deg) translate(-146%);
  transform: rotate(-90deg) translate(-146%);
}

.weekly-view table:first-child {
  margin-top: 1rem;
}

.text-h.less-providers {
  transform: none !important;
  position: relative !important;
  top: 25px !important;
  left: 0 !important;
}

.length-2 .text-h.less-providers {
  height: 50px;
  padding: 15px 0;
}

.mqy-view .length-2 .text-h.less-providers {
  height: 50px;
  padding: 15px 0;
}

.maintenance .tb-head {
  padding-left: 112px;
}
.maintenance .tb-head.less-providers {
  padding-left: 170px;
}

.maintenance-datasheet thead tr th:first-child,
.maintenance-datasheet tbody.actual-data tr td:first-child {
  padding: 0 12px 0 12px;
}

.maintenance table {
  background: none;
}

.maintenance .table td.data-td {
  background: #303030;
}

.delta.dropdown-dialog .k-link {
  font-size: 12px;
}

.maintenance .options {
  position: relative;
}

.maintenance-select {
  position: absolute;
  background: #202020;
  z-index: 99;
  top: 66px;
  width: 100%;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 435px;
}

.selected-item {
  border: 1px solid #7f7f7f;
  padding: 0.5rem 1rem;
  border-radius: 25px;
}

.reset-clear {
  color: #f7f7f7;
  background: #000;
  border: 1px solid #7f7f7f;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}

.filter .fa {
  color: #7f7f7f;
  font-size: 20px;
}

.options-li li {
  display: inline-block;
  margin-right: 0.75rem;
}

.selected-long-list {
  max-height: 51px;
  overflow: hidden;
}

.reset-clear {
  z-index: 999;
}

.selections button {
  margin: 5px 0;
}

.selected-item span {
  cursor: pointer;
  margin-left: 1rem;
}

.total-selected-items {
  padding: 0.5rem 1rem;
}

.total-selected-items span {
  color: #fff;
}

.all-selected-items {
  position: absolute;
  background: #202020;
  z-index: 99;
  top: 66px;
  width: 100%;
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  height: 400px;
}

.selected-item-list li {
  display: inline-block;
}

.selected-item-list li button {
  padding-right: 0;
}

.all-selected-items .row,
.all-selected-items .row:active {
  border-top: 1px solid #595959 !important;
  border-left: 1px solid #595959 !important;
  border-right: 1px solid #595959 !important;
}

.all-selected-items .row:last-child {
  border-bottom: 1px solid #595959 !important;
  margin-bottom: 1rem !important;
}

.table td,
.table th {
  padding: 0;
}

.maintenance-select::before {
  /* content: "\f077";
  font-family: FontAwesome;
  width: 30px;
  position: absolute;  */
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 36px;
  margin-left: -36px;
  width: 30px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.maintenance .table td.data.bg-green {
  background: #008443;
  border: 1px solid #66b58e !important;
  font-weight: 900;
}

.maintenance .table td.data.bg-green input {
  font-weight: 900;
}

.maintenance .table td.data.bg-red {
  background: #dd1d21;
  border: 1px solid #f1a5a6 !important;
  font-weight: 900;
}

.maintenance .table td.data.bg-red input {
  font-weight: 900;
}

.maintenance a.active {
  background: #003c88;
}

.page-title h3 {
  font-family: 'ShellMedium';
  font-size: 1.6rem;
}

.add-edit-btn {
  background-color: #ccd8e7;
  padding: 0.5rem 1rem;
  color: #3363a0;
  font-family: 'ShellMedium';
  font-size: 14px;
  font-weight: 900;
  border-radius: 8px;
  line-height: 20px;
}

.add-edit-btn i {
  color: #3363a0;
  font-size: 14px;
  margin-right: 1rem;
  font-weight: bold;
}

.download-btn i {
  color: #ccd8e7;
  font-size: 1.6rem;
}

.maintenance-filter a,
.maintenance-filter span.k-widget,
.maintenance-filter button {
  font-family: 'ShellMedium';
}

.weekly-view .data-td {
  min-width: 75px;
}

.simplebar-horizontal {
  visibility: hidden;
}

.pop-over {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.title,
.comment-box-title {
  color: #a6a6a6;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 17px;
  z-index: 99;
  left: 5px;
  background: #202020;
  top: -10px;
  padding: 0 5px;
}

.modal-window .k-dropdown-wrap.k-state-default {
  border: 0;
}

.unit-capacity-in-db-bg {
  border-radius: 4px 0 0 4px;
  background-color: #ffeac2;
}

.unit-capacity-in-db p {
  color: #404040;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.unit-capacity-in-db-bg {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.k-window-content {
  overflow: hidden;
}

.maintenance .k-window-wrapper,
.maintenance .k-dropdown-wrap,
.maintenance .modal-window input,
.maintenance .input-group-text {
  background: #202020 !important;
}

.input-group-text i {
  color: #fff;
}

.maintenance .k-header {
  border: 0;
  background-color: #003c88;
}
.k-window-titlebar {
  padding: 2rem 2rem 2rem 2rem;
}

.k-window-title,
.entry-button {
  font-family: 'ShellBold';
}

.k-window {
  box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.4);
  background: #202020;
}

.k-header button.k-button-icon.k-button.k-bare:nth-child(0) {
  display: none !important;
}
.k-header button.k-button-icon.k-button.k-bare:nth-child(1) {
  display: none !important;
}

.select-options-bg option {
  background-color: #404040;
  color: #fff;
}

.td-scroller {
  padding-bottom: 1.6rem;
}

html {
  overflow: -moz-scrollbars-none;
}

:-moz-any(#content, #appcontent) browser {
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll;
}
.eventemptyth {
  width: 30px !important;
}
.eventemptytd {
  padding-left: 8px;
  padding-top: 5px;
  width: 32px !important;
}
/* .eventth {
  padding: 3px 0 3px 0;
  width: 100px !important;
}
.eventtd {
  padding: 3px 0 3px 0;
  width: 100px !important;
} */

.ceventth {
  width: 126px !important;
}

.ceventtd {
  width: 126px !important;
}

#eventListing table {
  width: 100%;
}
#eventListing th {
  background-color: #282828 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 19px;
  font-family: 'ShellLight';
  border-top: 1px solid #404040;
  width: auto;
}

#eventListing tr {
  background-color: #303030;
}

#eventListing tr:nth-child(even) {
  background-color: #383838;
}
#eventListing tr:hover {
  background-color: rgb(0, 60, 136, 0.2);
}

#eventListing tr.selected-row {
  background-color: #003c88;
}

.ellipse-table {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

#dailyeventListing table {
  width: 100%;
}
#dailyeventListing th {
  background-color: #282828 !important;
  box-shadow: inset 0 1px 0 0 #404040, inset 0 -1px 0 0 #404040;
  color: #d9d9d9;
  line-height: 19px;
  font-family: 'ShellMedium';
  border-top: 1px solid #404040;
  text-align: left;
  padding-left: 20px;
  font-weight: normal;
}

#dailyeventListing .k-grid-header-wrap {
  padding: 0;
}

#dailyeventListing .k-link {
  font-size: 13px;
  font-weight: bold;
}

#dailyeventListing tr:nth-child(odd) {
  background-color: #303030;
}

#dailyeventListing tr:nth-child(even) {
  background-color: #383838;
}
#dailyeventListing td {
  padding: 8px 8px 8px 20px;
  /* width: 10%; */
  border-width: 1px 0 1px 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#dailyeventListing .k-alt {
  background-color: #383838;
}
#dailyeventListing tr:hover {
  background-color: rgb(0, 60, 136, 0.2);
}
#dailyeventListing .k-grid {
  background-color: #202020 !important;
  border: none !important;
}
#dailyeventListing .k-header:last-child {
  border-right: none !important;
}
#dailyeventListing .k-grid-footer-wrap,
.k-grid-header-wrap {
  border-width: 0;
  padding-right: 10px;
  /* background-color: rgb(24, 24, 24) !important; */
}
#dailyeventListing .k-state-selected {
  background-color: #003c88 !important;
}

tr.show {
  display: table-row !important;
}
.dot-planned {
  height: 15px;
  width: 15px;
  background-color: #885614;
  border-radius: 50%;
  border: 1px solid #dea033;
  display: inline-block;
  margin-top: 4px;
}
.dot-unplanned {
  height: 15px;
  width: 15px;
  background-color: #081149;
  border-radius: 50%;
  border: 1px solid #422cc7;
  display: inline-block;
  margin-top: 4px;
}

.dot-limit1 {
  height: 15px;
  width: 15px;
  background-color: #038000;
  border-radius: 50%;
  border: 1px solid #08bd05;
  display: inline-block;
  margin-top: 4px;
}

.dot-limit2 {
  height: 15px;
  width: 15px;
  background-color: #dd1d21;
  border-radius: 50%;
  border: 1px solid #fb6969;
  display: inline-block;
  margin-top: 4px;
}

.font-shell-light {
  font-family: 'ShellLight';
}
.Unplanned {
  background-color: #885614 !important;
  border: 1px solid #dea033 !important;
}
.Planned {
  background-color: #081149 !important;
  border: 1px solid #422cc7 !important;
}
.limit-more {
  background-color: #038000 !important;
  border: 1px solid #08bd05 !important;
}
.limit-less {
  background-color: #dd1d21 !important;
  border: 1px solid #fb6969 !important;
}
.nobgcbd {
  background: none !important;
  border: none !important;
}
.left-caption {
  width: 110px;
  top: -31px;
  background: #003c88;
  border-top: none;
}
.th-border {
  border: 1px solid #595959 !important;
  border-radius: 4px !important;
}

.sticky-headers {
  position: sticky;
  top: 0;
  background-color: #202020;
  z-index: 10;
}
.month-year {
  font-family: 'ShellHeavy';
  text-transform: uppercase;
}

.provider-name {
  font-family: 'ShellLight';
}
.caption-oum {
  color: #a6a6a6;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 17px;
  padding: 0 5px;
}
#multiSelect .k-button {
  background-color: #003c88;
  margin: 0;
}
#multiSelect .k-reset {
  float: right;
  margin-top: 1.5px;
}
#multiSelect .k-floatwrap {
  border: none;
  background-color: #202020;
  padding-right: 0;
}

.pointer-event {
  cursor: pointer;
}
.k-window-titlebar {
  background-color: #202020;
}

.topnav-button {
  background-color: #ccd8e7;
  border: 1px solid #595959;
  color: #003c88;
  border-radius: 6px;
  padding: 4px 6px 4px 0px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.topnav-button i {
  color: #003c88;
}

.topnav-button:hover {
  background-color: transparent;
  color: white !important;
}

.topnav-button:hover i {
  color: white;
}

.k-pager-numbers .k-link {
  color: white !important;
}

.k-link .k-i-seek-w {
  color: white !important;
}

.k-link .k-i-arrow-w {
  color: white !important;
}

.k-link .k-i-arrow-e {
  color: white !important;
}

.k-link .k-i-seek-e {
  color: white !important;
}

.ant-popover-inner-content {
  overflow-y: auto;
  padding-top: 0 !important;
}
