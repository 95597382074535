.wrapper-dropdown {
  position: relative;
  background-color: #202020;
  border: 1px solid #595959 !important;
  border-radius: 0.25rem !important;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  margin: 3px;
  float: right;
}
.dropdown-list {
  position: absolute;
  background: #1d1d1d;
  width: 100%;
  display: block;
  border-radius: 3px;
  margin-top: 2px;
  border: 1px solid #595959;
  z-index: 999;
}
.list-option {
  cursor: pointer;
  padding: 10px;
}
.list-option:hover {
  background-color: #003c88;
}

.label-for {
  color: #a6a6a6;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 17px;
  z-index: 99;
  left: 5px;
  background: #202020;
  top: -10px;
  padding: 0 5px;
  position: absolute;
}
