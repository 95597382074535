.program-menu .dropdown-menu {
  box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.4);
  left: -0.3rem !important;
  width: 24.5rem;
  transform: translate3d(56px, 0px, 0px) !important;
  height: 100%;
}

.program-menu.dropright {
  position: unset;
}

.Collapsible__trigger .is-open {
  border: 0;
  box-sizing: unset;
}

.program-menu .dropdown-item {
  padding: 0.7rem 0 0.7rem 0.475rem;
  font-size: 14px;
  line-height: 17px;
}

.sub-heading {
  font-family: 'Futura Std';
  font-size: 14px;
  font-weight: 900;
  line-height: 17px;
}

.program-menu .sidebar-menu-item .dropdown-menu b {
  padding: 0.7rem 0 0.7rem 0.475rem;
  font-size: 14px;
  line-height: 17px;
}

.grid-custom-daterangepicker .k-textbox-container {
  padding-top: 0;
}

.grid-custom-daterangepicker .k-textbox-container > .k-label {
  transform: none;
  top: 6px;
}

.grid-custom-daterangepicker .start-date .k-dateinput-wrap .k-input {
  padding-left: 55px;
}

.grid-custom-daterangepicker .end-date .k-dateinput-wrap .k-input {
  padding-left: 35px;
}

.child-tr {
  display: none;
}

.incr-cap-cl {
  width: 110%;
  position: relative;
  left: -26px;
}
input[type='radio']:before {
  width: 30% !important;
  height: 30% !important;
  margin: 36% !important;
}
#eventListing input[type='radio'] {
  width: 16px !important;
  height: 16px !important;
}

.current-history-item {
  width: 100px;
  text-align: left;
}
.k-today {
  color: #d9d9d9 !important;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;
  position: relative;
}
.k-today:hover {
  background: #404040;
}
.chart-continer {
  background: #202020;
  border: 1px solid #595959;
  height: 50%;
  width: 40%;
  position: fixed;
  bottom: 10px;
  right: 27px;
}
